import BaseStyle from '../assets/css/Authentication.module.css';

const PageNotFound = () => {
  return <>
    <div className={BaseStyle.authentication}>
      <div className={BaseStyle.logo_bg}>
        <img className={BaseStyle.logo} src={`https://cdn.codnum.fr/watch4safe/assets/images/logo_white.svg`}
             alt="Logo watch4safe"/>
      </div>

      <h2>Cette page n’est pas disponible.</h2>
      <span>Veuillez utiliser l’application mobile <b>Watch4Safe</b> pour accéder à ce service.</span>

      <a href={'https://apps.apple.com/fr/app/watch4safe/id6572307852'} target={'_blank'}>
        <img style={{height: '80px'}} src={`https://vault.watch4safe.fr/public/assets/download_apple.svg`}
             alt="Télécharger sur l'app store"/>
      </a>

      <a href={'https://play.google.com/store/apps/details?id=com.codnum.watch4safe'} target={'_blank'}>
        <img style={{height: '80px'}} src={`https://vault.watch4safe.fr/public/assets/download_google.svg`}
             alt="Télécharger sur le google play store"/>
      </a>
        <div className={BaseStyle.version}>
          <span>v{process.env.REACT_APP_VERSION}</span>
        </div>
    </div>
  </>
;
};

export default PageNotFound;
